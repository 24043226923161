import React, { Component } from 'react';
import {
  Form, Input, Button, Checkbox, Typography, Radio, Alert,
} from 'antd';
import { inject } from 'mobx-react';
import axios from 'axios';
import mapato_banner from '../../images/MobiTill-on-White-bg.png';

const { Text } = Typography;
@inject('store') class Login extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      error: null,
      isLoading: false,
    };
  }

    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.handleLogin(values);
        }
      });
    };

    setPass = () => {
      this.props.history.push('/set');
    }

    signUp = () => {
      this.props.history.push('/signup');
    }

    handleLogin = (values) => {
      this.setState({
        error: null,
        isLoading: true,
      });

      const data = {};
      data.email = values.email;
      data.password = values.password;
      data.type = values.log_type;

      axios.post(`${this.store.domain}/auth/login`, { data })
        .then((response) => {
          if (response.data.type === 'no_pass') {
            this.props.history.push('/set');
            return;
          }

          const { token, uid, eid } = response.data.data;
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('uid', uid || null);
          sessionStorage.setItem('eid', eid || null);
          sessionStorage.setItem('type', values.log_type);

          if (uid) this.props.history.push('/organization/account');
          if (eid) this.props.history.push('/account');
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              isLoading: false,
              error: error.response && error.response.data.error || 'something went wrong',
            });
            return;
          }
          this.setState({
            isLoading: false,
            error: 'something went wrong',
          });
        });
      // }
    };

    render() {
      const { error } = this.state;
      const { getFieldDecorator } = this.props.form;
      return (
        <div>
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%', width: '100%',
          }}
          >

            <div
              className="flex_center"
              style={{
                display: 'flex', width: '60%', height: '100%', margin: 'auto',
              }}
            >
              <img src={mapato_banner} alt="logos" style={{ width: '60%', margin: 'auto' }} />
            </div>

            <div style={{
              width: '40%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
            }}
            >

              <Form onSubmit={this.handleSubmit} className="login-form" style={{ margin: 'auto' }}>

                <Text strong style={{ fontSize: '25px', color: '#000000', fontWeight: 'bold' }}>Log in</Text>
                <br />
                <br />
                <br />
                <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email!' }],
                  })(
                    <Input
                      size="large"
                      // prefix={<LockOutlined />}
                      placeholder="Enter your email address"
                    />,
                  )}
                </Form.Item>
                <br />
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input
                      size="large"
                      // prefix={ <LockOutlined /> }
                      type="password"
                      placeholder="Password"
                    />,
                  )}
                </Form.Item>

                <Text>Select if you are a business or employee</Text>
                <Form.Item>
                  {getFieldDecorator('log_type', {
                    rules: [{ required: true, message: 'Please select if you are a business or employee!' }],
                  })(
                    <Radio.Group name="log_type">
                      <Radio value="business">Business</Radio>
                      <Radio value="employee">Employee</Radio>
                    </Radio.Group>,
                  )}

                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(<Checkbox>Remember me</Checkbox>)}

                  <Button type="link" onClick={this.setPass}>
                    Forgot password
                  </Button>
                  <br />
                  <br />
                  <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.state.isLoading}>
                    Log in
                  </Button>
                  ..Or
                  <br />
                  <Button type="link" size="large" onClick={this.signUp}>
                      Register an account
                  </Button>

                </Form.Item>

                {error
                && (
                <Alert
                  message="Error"
                  description={error}
                  type="error"
                  closable
                />
                )}
              </Form>

              <div />
            </div>
          </div>
        </div>
      );
    }
}

const WrappedNormalLoginForm = Form.create({ name: 'space_login_form' })(Login);

export default WrappedNormalLoginForm;
