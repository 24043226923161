/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import axios from 'axios';
import moment from 'moment';

import {
  Form, Input, DatePicker, Select, Radio, Typography, Alert, Divider, Button, Checkbox,
  message,
} from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';

import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { boldFormatter, italicFormatter, priceFormatter } from '../../../components/TableFormatter';

const { Option } = Select;
const { Text, Title } = Typography;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

@inject('store') class EmployeeForm extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.mode = this.props.mode;
    this.employee = this.props.employee;
    this.state = {
      isLoading: false,
      error: null,
      success: null,
      selected_item: this.props.selected_item || 'details',
      finishRender: false,
      deductions: [],
      allowances: [],
      banks: [],
      selected_deduction: null,
      selected_allowance: null,
    };
  }

    config = {
      headers: { authorization: sessionStorage.getItem('token') },
    }

    getBanks = () => {
      this.setState({ error: null });
      axios.get(`${this.store.domain}/users/${sessionStorage.getItem('organisation_id')}/${this.employee.eid}/bank_details`, this.config)
        .then((response) => {
          const { data } = response.data;
          this.setState({ banks: data });
        })
        .catch((error) => {
          this.setState({ error: error.response.data.error });
        });
    };

    setFields = () => {
      if (this.mode === 'edit') {
        Object.keys(this.employee).forEach((key) => {
          let value = this.employee[key] || null;
          if (key === 'dob') {
            if (value) {
              value = moment(value);
            } else {
              value = null;
            }
          }
          this.props.form.setFieldsValue({ [key]: value });
        });
      }
    }

    componentDidMount() {
      this.setFields();
      this.props.form.validateFields();
      if (this.employee) {
        const deductions = this.employee.amounts.filter((item) => item.type === 'deduction');
        const allowances = this.employee.amounts.filter((item) => item.type === 'allowance');
        this.setState({ deductions, allowances });
      }
    }

    handleCreateNewEmployee = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) this.createNewEmployee(values);
      });
    };

    handleUpdateEmployee = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) this.updateEmployee(values);
      });
    };

    createNewEmployee = (values) => {
      const data = values;
      data.organisation_id = sessionStorage.getItem('organisation_id');
      this.setState({ success: false, isLoading: true, error: null });
      axios.post(`${this.store.domain}/employees`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
        .then(() => {
          this.setState({ success: true, isLoading: false, error: null });
          this.getEmployees();
          this.props.history.push('/organization/employees');
        })
        .catch((error) => {
          this.setState({ error: error.response && error.response.data.error, isLoading: false, success: false });
        });
    }

    getEmployees = () => {
      message.info('Updating employees...');
      this.store.getEmployees()
        .then(() => {
          message.info('Update completed!');
        })
        .catch(() => {
          message.error('There was an error updating employees!');
        });
    };

    updateEmployee = (values) => {
      const data = this.validateValues(values);
      this.setState({ success: true, isLoading: true, error: null });
      axios.put(`${this.store.domain}/employees/${sessionStorage.getItem('organisation_id')}/${this.employee.eid}`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
        .then(() => {
          this.setState({ success: true, isLoading: false, error: null });
          this.getEmployees();
        })
        .catch((error) => {
          this.setState({ isLoading: false, error: error.response && error.response.data.error, success: false });
        });
    }

    validateValues = (values) => {
      if (values.basic_salary && isNaN(values.basic_salary)) values.basic_salary = Number(values.basic_salary.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.retainer_fee && isNaN(values.retainer_fee)) values.retainer_fee = Number(values.retainer_fee.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.wh_tax_percent && isNaN(values.wh_tax_percent)) values.wh_tax_percent = Number(values.wh_tax_percent.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.personal_relief && isNaN(values.personal_relief)) values.personal_relief = Number(values.personal_relief.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.insurance_relief && isNaN(values.insurance_relief)) values.insurance_relief = Number(values.insurance_relief.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.pension_fund_contribution && isNaN(values.pension_fund_contribution)) values.pension_fund_contribution = Number(values.pension_fund_contribution.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.hosp_contibution && isNaN(values.hosp_contibution)) values.hosp_contibution = Number(values.hosp_contibution.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.life_insurance_contibution && isNaN(values.life_insurance_contibution)) values.life_insurance_contibution = Number(values.life_insurance_contibution.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.mortgage_contibution && isNaN(values.mortgage_contibution)) values.mortgage_contibution = Number(values.mortgage_contibution.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.disability_exemption && isNaN(values.disability_exemption)) values.disability_exemption = Number(values.disability_exemption.replace(/[^0-9\.]+/g, '')) || 0;
      return values;
    }

    handleStepSelection = (e) => {
      this.setState({ selected_item: e.target.value, success: false, error: null }, () => {
        this.setFields();
        this.props.form.validateFields();
      });

      // if(e.target.value === 'allowances'  && this.state.allowances.length === 0){
      //   this.getAmounts()
      // }

      // if(e.target.value === 'deductions'  && this.state.deductions.length === 0){
      //   this.getAmounts()
      // }

      if (e.target.value === 'banks' && this.state.banks.length === 0) {
        this.getBanks();
      }
    }

  tableNodata = (string) => (
    <div style={{
      paddingTop: '40px', paddingBottom: '40px', margin: 'auto', textAlign: 'center', backgroundColor: '#f8f8f8',
    }}
    >
      <Text>
        {' '}
        {`No ${string} found!`}
        {' '}
      </Text>
    </div>
  )

  onBankDelete = (cell, row) => {

  }

  onDeleteAllowance = (cell, row) => {
    axios.delete(`${this.store.domain}/users/${sessionStorage.getItem('organisation_id')}/${this.employee.eid}/amounts`, { data: { id: row._id }, ...this.config })
      .then(() => {
        this.props.getEmployees();
        this.setState({ allowances: this.state.allowances.filter((item) => item._id !== row._id) });
      })
      .catch((error) => {
        this.setState({ error: error.response.data.error });
      });
  }

  onDeleteDeduction = (cell, row) => {
    axios.delete(`${this.store.domain}/users/${sessionStorage.getItem('organisation_id')}/${this.employee.eid}/amounts`, { data: { id: row._id }, ...this.config })
      .then(() => {
        this.props.getEmployees();
        this.setState({ deductions: this.state.deductions.filter((item) => item._id !== row._id) });
      })
      .catch((error) => {
        this.setState({ error: error.response.data.error });
      });
  }

  onEditDeduction = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, data_) => {
      if (!err) {
        const data = {};
        data.id = this.state.selected_deduction._id;
        data.name = data_.ded_name;
        data.amount = data_.ded_amount;
        data.billing_period = data_.billing_period;

        axios.put(`${this.store.domain}/users/${sessionStorage.getItem('organisation_id')}/${this.employee.eid}/amounts`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
          .then(() => {
            this.props.getEmployees();
            this.setState({ success: true, isLoading: false, error: null });
          })
          .catch((error) => {
            this.setState({ error: error.response.data.error, isLoading: false, success: false });
          });
      }
    });
  }

  showEdit = (cell, row) => {
    // e.preventDefault();
    this.setState({ selected_deduction: row });
  }

  onAddDeduction = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, data) => {
      if (!err) {
        data.name = data.ded_name;
        data.amount = data.ded_amount;
        data.type = 'deduction';
        delete data.ded_name;
        delete data.ded_amount;

        axios.post(`${this.store.domain}/users/${sessionStorage.getItem('organisation_id')}/${this.employee.eid}/amounts`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
          .then(() => {
            this.props.getEmployees();
            this.setState({ success: true, isLoading: false, error: null });
          })
          .catch((error) => {
            this.setState({ error: error.response.data.error, isLoading: false, success: false });
          });
      }
    });
  }

  onAddAllowance = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, data) => {
      if (!err) {
        data.name = data.allowance_name;
        data.amount = data.allowance_amount;
        data.type = 'allowance';
        delete data.allowance_name;
        delete data.allowance_amount;
        axios.post(`${this.store.domain}/users/${sessionStorage.getItem('organisation_id')}/${this.employee.eid}/amounts`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
          .then(() => {
            this.props.getEmployees();
            this.setState({ success: true, isLoading: false, error: null });
          })
          .catch((error) => {
            this.setState({ error: error.response.data.error, isLoading: false, success: false });
          });
      }
    });
  }

  banksDeleteFormatter(cell, row) {
    return (
      <Button type="danger" onClick={() => this.onBankDelete(cell, row)}>Delete</Button>
    );
  }

  allowancesDeleteFormatter(cell, row) {
    return (
      <Button type="danger" onClick={() => this.onDeleteAllowance(cell, row)}>Delete</Button>
    );
  }

  deductionsDeleteFormatter(cell, row) {
    return (
      <Button type="danger" onClick={() => this.onDeleteDeduction(cell, row)}>Delete</Button>
    );
  }

  deductionsEditFormatter(cell, row) {
    return (
      <Button type="link" onClick={() => this.showEdit(cell, row)}>Edit</Button>
    );
  }

  TitleComponent = () => (
    <div style={{
      display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', marginLeft: '30px', marginTop: '20px',
    }}
    >
      <div>
        <p className="main_header">
          {this.mode === 'edit' ? 'Update employee' : 'Create new employee'}
        </p>
        <p>
          {this.mode === 'edit' ? `Names - ${this.employee.name || ''}` : ''}
        </p>
      </div>
      <div style={{ marginRight: '360px' }}>
        <Radio.Group defaultValue={this.state.selected_item || 'details'} buttonStyle="solid" onChange={this.handleStepSelection}>
          <Radio.Button value="details">Details</Radio.Button>
          <Radio.Button value="work_details" disabled={Boolean(this.mode === 'add')}>Work Details</Radio.Button>
          <Radio.Button value="allowances" disabled={Boolean(this.mode === 'add')}>Allowances</Radio.Button>
          <Radio.Button value="deductions" disabled={Boolean(this.mode === 'add')}>Deductions</Radio.Button>
          <Radio.Button value="banks" disabled={Boolean(this.mode === 'add')}>Banks</Radio.Button>
          <Radio.Button value="taxes" disabled={Boolean(this.mode === 'add')}>Taxes</Radio.Button>
        </Radio.Group>
      </div>
      <div />
    </div>
  )

  BankComponent = () => {
    const {
      getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
    } = this.props.form;

    const columns = [
      {
        dataField: 'bank_name', text: 'Bank name', filter: textFilter(), headerFormatter: this.headerFormatter,
      },
      {
        dataField: 'ch_name', text: 'Cardholder name', filter: textFilter(), headerFormatter: this.headerFormatter,
      },
      {
        dataField: 'account_no', text: 'Account number', filter: textFilter(), headerFormatter: this.headerFormatter,
      },
      {
        dataField: 'code', text: 'Bank code', filter: textFilter(), headerFormatter: this.headerFormatter,
      },
      { dataField: '', text: 'Action', formatter: this.banksDeleteFormatter.bind(this) },
    ];

    const formItemLayout = {
      labelCol: {
        xs: { span: 5 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };

    const nameError = isFieldTouched('bank_name') && getFieldError('bank_name');
    const accError = isFieldTouched('account_no') && getFieldError('account_no');

    return (
      <div className="grid_margin card">
        <div>
          <BootstrapTable
            bootstrap4
            noDataIndication={this.tableNodata('bank accounts')}
            keyField="id"
            data={this.state.banks}
            columns={columns}
          />
        </div>
        <div>
          <Title level={4}>Add or edit bank account</Title>
          <Form {...formItemLayout} onSubmit={this.handleUpdateEmployee}>
            <Form.Item label="Bank name" validateStatus={nameError ? 'error' : ''} help={nameError || ''}>
              {getFieldDecorator('bank_name', { rules: [{ required: true, message: 'Please input bank name' }] })(
                <Input placeholder="eg Cooperative bank" />,
              )}
            </Form.Item>

            <Form.Item label="Account number" validateStatus={accError ? 'error' : ''} help={accError || ''}>
              {getFieldDecorator('account_no', { rules: [{ required: true, message: 'Please input account_no' }] })(
                <Input placeholder="eg 11234567891234567" />,
              )}
            </Form.Item>

            <Form.Item label="Bank Branch">
              {getFieldDecorator('branch', { rules: [{ required: false }] })(
                <Input placeholder="eg Nairobi branch" />,
              )}
            </Form.Item>

            <Form.Item label="Holder's name">
              {getFieldDecorator('ch_name', { rules: [{ required: false }] })(
                <Input placeholder="eg John D Doe" />,
              )}
            </Form.Item>

            <Form.Item label="Label">
              {getFieldDecorator('label', { rules: [{ required: false }] })(
                <Input placeholder="eg Main Account" />,
              )}
            </Form.Item>

            <Form.Item label="Bank Code">
              {getFieldDecorator('code', { rules: [{ required: false }] })(
                <Input placeholder="eg EQT" />,
              )}
            </Form.Item>

            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())} loading={this.state.isLoading}>
             Add or update account
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }

  DeductionComponent = () => {
    const {
      getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
    } = this.props.form;

    const columns = [
      { dataField: 'name', text: 'Name of deduction', ...boldFormatter },
      { dataField: 'amount', text: 'Amount of deduction', ...priceFormatter },
      { dataField: 'amount_number', text: 'amount_number', hidden: true },
      // eslint-disable-next-line object-curly-newline
      { dataField: 'billing_period', text: 'period', align: 'right', ...italicFormatter },
      { dataField: '', text: 'Action', formatter: this.deductionsEditFormatter.bind(this) },
      { dataField: '', text: 'Action', formatter: this.deductionsDeleteFormatter.bind(this) },
    ];

    const typeError = isFieldTouched('name') && getFieldError('name');
    const amountError = isFieldTouched('amount') && getFieldError('amount');
    const deductionBillingError = isFieldTouched('billing_period') && getFieldError('billing_period');

    const { selected_deduction } = this.state;
    return (
      <div className="grid_margin card" style={{ width: '60%' }}>
        <div>
          <BootstrapTable
            keyField="id"
            bootstrap4
            noDataIndication={this.tableNodata('deductions')}
            data={this.state.deductions}
            columns={columns}
          />
        </div>
        <div>

          <Divider />
          <Title type="primary" level={3}>
            { selected_deduction && 'Edit deduction' || 'Add deduction' }
          </Title>

          <Form layout="vertical" onSubmit={selected_deduction && this.onEditDeduction || this.onAddDeduction} style={{ width: '50%' }}>
            <Form.Item label="Deduction name" validateStatus={typeError ? 'error' : ''} help={typeError || ''}>
              {getFieldDecorator('ded_name', { initialValue: selected_deduction && selected_deduction.name || '' }, { rules: [{ required: true, message: 'Please input deduction name' }] })(
                <Input placeholder="eg insurance" />,
              )}
            </Form.Item>

            <Form.Item label="Deduction amount" validateStatus={amountError ? 'error' : ''} help={amountError || ''}>
              {getFieldDecorator('ded_amount', { initialValue: selected_deduction && selected_deduction.amount || '' }, { rules: [{ required: true, message: 'Please input deduction amount' }] })(
                <Input placeholder="eg 3000" />,
              )}
            </Form.Item>

            <Form.Item label="Billing period" validateStatus={deductionBillingError ? 'error' : ''} help={deductionBillingError || ''}>
              {getFieldDecorator('billing_period', { initialValue: selected_deduction && selected_deduction.billing_period || '' }, { rules: [{ required: true, message: 'Select deduction billing period' }] })(
                <Select
                  placeholder="Select deduction billing period!"
                >
                  <Option value="hourly">hourly</Option>
                  <Option value="daily">daily</Option>
                  <Option value="weekly">weekly</Option>
                  <Option value="monthly">monthly</Option>
                  <Option value="unspecified">unspecified</Option>
                </Select>,
              )}
            </Form.Item>

            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())} loading={this.state.isLoading}>
                { selected_deduction && 'Edit deduction' || 'Add deduction' }
              </Button>
            </Form.Item>
          </Form>

        </div>
      </div>
    );
  }

  AllowancesComponent = () => {
    const {
      getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
    } = this.props.form;
    const columns = [
      { dataField: 'name', text: 'Type of allowance', ...boldFormatter },
      { dataField: 'amount', text: 'Amount', ...priceFormatter },
      {
        dataField: 'billing_period', text: 'Billing period', align: 'right', ...italicFormatter,
      },
      { dataField: '', text: 'Action', formatter: this.allowancesDeleteFormatter.bind(this) },
    ];

    const typeError = isFieldTouched('name') && getFieldError('name');
    const amountError = isFieldTouched('amount') && getFieldError('amount');
    const allowanceBillingError = isFieldTouched('billing_period') && getFieldError('billing_period');

    return (
      <div className="grid_margin card" style={{ width: '60%' }}>
        <div>
          <BootstrapTable
            keyField="id"
            bootstrap4
            noDataIndication={this.tableNodata('allowances')}
            data={this.state.allowances}
            columns={columns}
            filter={filterFactory()}
          />
        </div>
        <div>
          <Title level={3}>Add allowance/benefit</Title>

          <Form layout="vertical" onSubmit={this.onAddAllowance} style={{ width: '35%' }}>
            <Form.Item label="Allowance name" validateStatus={typeError ? 'error' : ''} help={typeError || ''}>
              {getFieldDecorator('allowance_name', { rules: [{ required: true, message: 'Please input allowance name' }] })(
                <Input placeholder="eg Transport allowance" />,
              )}
            </Form.Item>

            <Form.Item label="Allowance amount" validateStatus={amountError ? 'error' : ''} help={amountError || ''}>
              {getFieldDecorator('allowance_amount', { rules: [{ required: true, message: 'Please input allowance amount' }] })(
                <Input placeholder="eg 3000" />,
              )}
            </Form.Item>

            <Form.Item label="Billing period" validateStatus={allowanceBillingError ? 'error' : ''} help={allowanceBillingError || ''}>
              {getFieldDecorator('billing_period', {
                rules: [{ required: true, message: 'Select allowance billing period' }],
              })(
                <Select
                  placeholder="Select allowance billing period!"
                >
                  <Option value="hourly">hourly</Option>
                  <Option value="daily">daily</Option>
                  <Option value="weekly">weekly</Option>
                  <Option value="monthly">monthly</Option>
                  <Option value="unspecified">unspecified</Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())} loading={this.state.isLoading}>
             Add allowance
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }

    WorkDetailsComponent = () => {
      const {
        getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
      } = this.props.form;
      const employeeTypeError = isFieldTouched('employee_type') && getFieldError('employee_type');
      const salaryBillingError = isFieldTouched('salary_billing') && getFieldError('salary_billing');
      const nhifError = isFieldTouched('nhif') && getFieldError('nhif');
      const pinError = isFieldTouched('kra_pin') && getFieldError('kra_pin');

      const layout1 = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };

      const emp_type = this.props.form.getFieldValue('employee_type');
      const is_contractor_or_commission = emp_type === 'commission' || emp_type === 'contractor';
      return (
        <div
          className="grid_margin card"
          style={{
            display: 'flex', flexDirection: 'row', width: '99%', margin: 'auto',
          }}
        >

          <div style={{ flexGrow: '1', alignItems: 'center', marginTop: '50px' }}>

            <Form onSubmit={this.handleUpdateEmployee}>

              <Form.Item label="Employee type" {...layout1} validateStatus={employeeTypeError ? 'error' : ''} help={employeeTypeError || ''}>
                {getFieldDecorator('employee_type', {
                  rules: [{ required: true, message: 'Select type of employee' }],
                })(
                  <Select
                    placeholder="Select type of employee!"
                  >
                    <Option value="permanent">Permanent</Option>
                    <Option value="contractor">Contractor</Option>
                    <Option value="commission">Commission</Option>
                    <Option value="intern">Intern</Option>
                    <Option value="non-permanent">Non-permanent</Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item label="Basic Salary / Lump sum" {...layout1} style={{ display: emp_type === 'commission' ? 'none' : '' }}>
                {getFieldDecorator('basic_salary', { rules: [{ required: false }] })(
                  <Input placeholder="for example 50000" />,
                )}
              </Form.Item>

              <Form.Item label="Retainer Fee" {...layout1} style={{ display: is_contractor_or_commission ? '' : 'none' }}>
                { getFieldDecorator('retainer_fee', { rules: [{ required: false }] })(
                  <Input placeholder="50000" />,
                )}
              </Form.Item>

              <Form.Item {...layout1} label="Emergency Phone">
                {getFieldDecorator('emergency_phones', { rules: [{ required: false }] })(
                  <Input placeholder="Eg 0712345678" />,
                )}
              </Form.Item>

              <Form.Item label="Salary Billing" {...layout1} validateStatus={salaryBillingError ? 'error' : ''} help={salaryBillingError || ''}>
                {getFieldDecorator('salary_billing', {
                  rules: [{ required: true, message: 'Select salary billing period' }],
                })(
                  <Select
                    placeholder="Select salary billing period!"
                  >
                    <Option value="hourly">hourly</Option>
                    <Option value="daily">daily</Option>
                    <Option value="weekly">weekly</Option>
                    <Option value="monthly">monthly</Option>
                    <Option value="unspecified">unspecified</Option>
                  </Select>,
                )}
              </Form.Item>

            </Form>

          </div>

          <div style={{ flexGrow: '1', alignItems: 'center', marginTop: '50px' }}>
            <Form onSubmit={this.handleUpdateEmployee}>

              <Form.Item label="Employee Id" {...layout1}>
                {getFieldDecorator('employee_id', { rules: [{ required: false }] })(
                  <Input placeholder="eg Employee 1" />,
                )}
              </Form.Item>

              <Form.Item label="NSSF Number" {...layout1}>
                {getFieldDecorator('nssf_no', { rules: [{ required: false, message: 'Please input NSSF Number' }] })(
                  <Input placeholder="NSSF no" />,
                )}
              </Form.Item>

              <Form.Item label="NHIF Number" {...layout1} validateStatus={nhifError ? 'error' : ''} help={nhifError || ''}>
                {getFieldDecorator('nhif_no', { rules: [{ required: false, message: 'Please input NHIF Number' }] })(
                  <Input placeholder="NHIF no" />,
                )}
              </Form.Item>

              <Form.Item label="PIN Number" {...layout1} validateStatus={pinError ? 'error' : ''} help={pinError || ''}>
                {getFieldDecorator('kra_pin', { rules: [{ required: false, message: 'Please input Personal Identification Number' }] })(
                  <Input placeholder="PIN Number" />,
                )}
              </Form.Item>

              <Form.Item label="Residential Status" {...layout1}>
                {getFieldDecorator('residential_status', { rules: [{ required: false, message: 'Please select residential status' }] })(
                  <Select
                    placeholder="If resides in country or not"
                  >
                    <Option value="residential">Residential</Option>
                    <Option value="non-residential">Non Residential</Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())} loading={this.state.isLoading}>
             Update work details
                </Button>
              </Form.Item>
            </Form>

          </div>
        </div>
      );
    }

    BasicDetailsComponent = () => {
      const {
        getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
      } = this.props.form;

      const lastNameError = isFieldTouched('name') && getFieldError('name');
      const phoneError = isFieldTouched('phone') && getFieldError('phone');
      const countryError = isFieldTouched('country') && getFieldError('country');
      const genderError = isFieldTouched('gender') && getFieldError('gender');
      const idError = isFieldTouched('national_id') && getFieldError('national_id');
      const dobError = isFieldTouched('dob') && getFieldError('dob');
      const jdError = isFieldTouched('job_desc') && getFieldError('job_desc');
      const deptError = isFieldTouched('department') && getFieldError('department');

      const layout1 = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };

      return (
        <div
          className="grid_margin card"
          style={{
            display: 'flex', flexDirection: 'row', width: '99%', margin: 'auto',
          }}
        >
          <div style={{ flexGrow: '1', alignItems: 'center', marginTop: '50px' }}>

            <Form onSubmit={this.mode === 'edit' ? this.handleUpdateEmployee : this.handleCreateNewEmployee}>

              <Form.Item label="Employee name" {...layout1} validateStatus={lastNameError ? 'error' : ''} help={lastNameError || ''}>
                {getFieldDecorator('name', { rules: [{ required: true, message: 'Please input name' }] })(
                  <Input placeholder="Employee name" />,
                )}
              </Form.Item>

              <Form.Item {...layout1} label="Phone Number" validateStatus={phoneError ? 'error' : ''} help={phoneError || ''}>
                {getFieldDecorator('phone', { rules: [{ required: true, whitespace: true, message: 'Please input phone number' }] })(
                  <Input placeholder="Eg 0712345678" />,
                )}
              </Form.Item>

              <Form.Item {...layout1} label="Email Address">
                {getFieldDecorator('email', { rules: [{ required: true }] })(
                  <Input placeholder="Eg abc@cdef.com" />,
                )}
              </Form.Item>

              <Form.Item {...layout1} label="Country" validateStatus={countryError ? 'error' : ''} help={countryError || ''}>
                {getFieldDecorator('country', { rules: [{ required: false, message: 'Please input employee country' }] })(
                  <Input placeholder="Eg Kenya" />,
                )}
              </Form.Item>

            </Form>

          </div>
          <div style={{ flexGrow: '1', alignItems: 'center', marginTop: '50px' }}>
            <Form onSubmit={this.mode === 'edit' ? this.handleUpdateEmployee : this.handleCreateNewEmployee}>

              <Form.Item label="Gender" {...layout1} validateStatus={genderError ? 'error' : ''} help={genderError || ''}>
                {getFieldDecorator('gender', {
                  rules: [{ required: true, message: 'Select gender' }],
                })(
                  <Select
                    placeholder="Select gender!"
                  >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="other">Other</Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item label="National Id" {...layout1} validateStatus={idError ? 'error' : ''} help={idError || ''}>
                {getFieldDecorator('national_id', { rules: [{ required: false, message: 'Please input employee national id ' }] })(
                  <Input placeholder="Eg 1234567" />,
                )}
              </Form.Item>

              <Form.Item label="Date of Birth" {...layout1} validateStatus={dobError ? 'error' : ''} help={dobError || ''}>
                {getFieldDecorator('dob', { rules: [{ type: 'object', required: false, message: 'Please select time!' }] })(<DatePicker />)}
              </Form.Item>

              <Form.Item {...layout1} label="Job Description" validateStatus={jdError ? 'error' : ''} help={jdError || ''}>
                {getFieldDecorator('job_desc', { rules: [{ required: false, message: 'Please input job description' }] })(
                  <Input placeholder="Eg Product Manager" />,
                )}
              </Form.Item>

              <Form.Item {...layout1} label="Department" validateStatus={deptError ? 'error' : ''} help={deptError || ''}>
                {getFieldDecorator('department', { rules: [{ required: false, message: 'Please input department name' }] })(
                  <Input placeholder="Eg Administration" />,
                )}
              </Form.Item>

              <Form.Item>

                <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())} style={{ textAlign: 'end' }} loading={this.state.isLoading}>
                  {this.mode === 'edit' ? 'Update employee' : 'Create new employee'}
                </Button>

              </Form.Item>

            </Form>

          </div>

        </div>

      );
    }

    TaxesComponent = () => {
      const {
        getFieldDecorator, getFieldsError,
      } = this.props.form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 12 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 12 },
          sm: { span: 16 },
        },
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };

      return (
        <div className="grid_margin card">
          <Form {...formItemLayout} onSubmit={this.handleUpdateEmployee}>

            <Form.Item {...tailFormItemLayout}>
              {getFieldDecorator('is_taxable', {
                valuePropName: 'checked',
                initialValue: true,
              })(<Checkbox>Employee is taxable (PAYE, NSSF, NHIF...)</Checkbox>)}
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              {getFieldDecorator('is_wht_enabled', {
                valuePropName: 'checked', initialValue: false,
              })(<Checkbox>Enable WithHolding Tax for employee</Checkbox>)}
            </Form.Item>

            <Form.Item label="Witholding Tax Percentage" style={{ display: this.props.form.getFieldValue('is_wht_enabled') === true ? '' : 'none' }}>
              {getFieldDecorator('wh_tax_percent', { rules: [{ required: false }] })(<Input placeholder="(For commission and contractors) Eg 50000" />)}
            </Form.Item>

            <Form.Item {...tailFormItemLayout} style={{ display: this.props.form.getFieldValue('is_wht_enabled') === true ? '' : 'none' }}>
              {getFieldDecorator('include_retainer_in_tax', {
                valuePropName: 'checked', initialValue: false,
              })(<Checkbox>Include retainer fee in tax calculation (For commission and contractors)</Checkbox>)}
            </Form.Item>

            { this.props.form.getFieldValue('is_taxable') === true
        && (
        <div>
          <Form.Item {...tailFormItemLayout}>
            {getFieldDecorator('new_nssf_rates', {
              valuePropName: 'checked', initialValue: false,
            })(<Checkbox>Use new NSSF Rates</Checkbox>)}
          </Form.Item>

          <Form.Item label="Personal relief">
            {getFieldDecorator('personal_relief', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
          </Form.Item>

          <Form.Item label="Insurance relief">
            {getFieldDecorator('insurance_relief', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
          </Form.Item>

          <Form.Item label="Pension fund contribution">
            {getFieldDecorator('pension_fund_contribution', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
          </Form.Item>

          <Form.Item label="HOSP contibution">
            {getFieldDecorator('hosp_contibution', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
          </Form.Item>

          <Form.Item label="Life insurance contibution">
            {getFieldDecorator('life_insurance_contibution', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
          </Form.Item>

          <Form.Item label="Mortgage contibution">
            {getFieldDecorator('mortgage_contibution', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
          </Form.Item>

          <Form.Item label="Disability exemption">
            {getFieldDecorator('disability_exemption', { rules: [{ required: false }] })(<Input placeholder="Eg 50000" />)}
          </Form.Item>

        </div>
        )
      }

            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())} loading={this.state.isLoading}>
            Update taxes
              </Button>
            </Form.Item>
          </Form>
        </div>
      );
    }

    SuccessErrorComponent = () => {
      const { success, error } = this.state;
      return (
        <div style={{
          marginLeft: '20px', marginRight: '20px', marginTop: '20px', marginBottom: '5px',
        }}
        >
          {error
          && (
          <Alert
            message="Error"
            description={error}
            type="error"
            closable
            showIcon
          />
          )}
          {success
            && (
            <Alert
              message="Success"
              description={this.mode === 'edit' ? 'Employee updated successfully' : 'New employee created'}
              type="success"
              closable
              showIcon
            />
            )}
        </div>
      );
    }

    render() {
      return (
        <div>
          <this.TitleComponent />
          <div className="overx">
            <this.SuccessErrorComponent />
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column stretched>
                  {this.state.selected_item === 'details' && <this.BasicDetailsComponent />}
                  {this.state.selected_item === 'work_details' && <this.WorkDetailsComponent />}
                  {this.state.selected_item === 'allowances' && <this.AllowancesComponent />}
                  {this.state.selected_item === 'deductions' && <this.DeductionComponent />}
                  {this.state.selected_item === 'banks' && <this.BankComponent />}
                  {this.state.selected_item === 'taxes' && <this.TaxesComponent />}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      );
    }
}

const AddEmployeeWrapper = Form.create({ name: 'add_emp_form' })(EmployeeForm);

export default AddEmployeeWrapper;
