/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable class-methods-use-this */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
import React, { Component, useState } from 'react';
import { Route, Switch } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import moment from 'moment';
import {
  Input, Button, Typography, Divider, Radio, Modal, Select, message, Alert, DatePicker, Drawer, Table,
} from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';

import Loader from '../../../components/Loader';
import { boldFormatter, italicFormatter, positionFormatter } from '../../../components/TableFormatter';
import PriceFormatter from '../../../components/PriceFormatter';

const { Text } = Typography;
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const { Option } = Select;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
@inject('store') class Payments extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoading: false,
      error: false,
      success: null,
      employees_filter: 'normal',
      disable_payment_button: false,
      employee_count: 0,
      amount_total: 0,
      payment_name: null,
      period_start: null,
      period_end: null,
      period_value: null,
      period_type: null,
      drawerVisible: false,
      paymentResultModalVisible: false,
      succeeded_payments: [],
      failed_payments: [],
      balance: 0,
    };
  }

  handleChange = (e) => {
    const change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  onDrawerClose = () => {
    this.setState({ drawerVisible: false });
  }

  onPaymentNameChange = (e) => {
    this.setState({ payment_name: e.target.value, disable_payment_button: true });

    if (e.target.value && e.target.value.length >= 14) {
      message.error('Payment name needs to be less than 15 characters.');
    } else {
      this.setState({ disable_payment_button: false });
    }
  }

  onDrawerOPen = () => {
    if (this.state.period_type === null) return message.error('Please select a payment month or week');
    if (this.state.payment_name === null) return message.error('Please enter a payment name or detail at the bottom', 5);

    if (!moment(this.state.period_start).isValid() && !moment(this.state.period_end).isValid() && this.state.period_value === null) return message.error('Please select a payment month or week');
    this.setState({ drawerVisible: true });
  }

  changeAmountstoZero = () => {
    const data = this.store.payments.map((value) => {
      value.amount = 0;
      value.employee_type = 'permanent';
    });

    this.store.updateValue('filter_payments', data);
    this.getEmployees(true);
  }

  updateOrganisationAccBalance = () => {
    axios.get(`${this.store.domain}/organisations/${sessionStorage.getItem('organisation_id')}/accounts/balance`, { headers: { authorization: sessionStorage.getItem('token') } })
      .then((response) => {
        const bal = response.data.bal || 'Unknown';
        message.info('Successfully fetched balance!');
        this.setState({ balance: bal });
      })
      .catch((error) => {
        this.setState({ balance: 'Unknown' });
        message.error('Failed to fetch balance!');
      });
  }

  changeAmountstoNetPay = () => {
    this.getEmployees();
  }

  sendPayments = () => {
    let data = JSON.parse(JSON.stringify(this.store.payments));
    data = data.filter((value) => value.amount > 0);
    if (data.length === 0) return message.warning('No payment created!');

    this.setState({ isLoading: true, error: false, success: null });

    data.forEach((values) => {
      if (!values.wh_tax) values.wh_tax = 0;
      values.period_type = this.state.period_type;
      if (this.state.period_start) values.period_start = this.state.period_start;
      if (this.state.period_end) values.period_end = this.state.period_end;
      values.payment_name = this.state.payment_name;
      values.period_value = this.state.period_value;
      values.ref_no = 'UNKNOWN';

      if (values.amount && isNaN(values.amount)) values.amount = Number(values.amount.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.wh_tax_percent && isNaN(values.wh_tax_percent)) values.wh_tax_percent = Number(values.wh_tax_percent.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.wh_tax && isNaN(values.wh_tax)) values.wh_tax = Number(values.wh_tax.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.total_sales && isNaN(values.total_sales)) values.total_sales = Number(values.total_sales.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.commission && isNaN(values.commission)) values.commission = Number(values.commission.replace(/[^0-9\.]+/g, '')) || 0;
      if (values.commission_percent && isNaN(values.commission_percent)) values.commission_percent = Number(values.commission_percent.replace(/[^0-9\.]+/g, '')) || 0;
    });

    this.onDrawerClose();
    axios.post(`${this.store.domain}/payments/${sessionStorage.getItem('organisation_id')}`, { data }, { headers: { authorization: sessionStorage.getItem('token') } })
      .then((response) => {
        const { succeeded, failed, session_id } = response.data;
        this.setState({
          isLoading: false, success: true, paymentResultModalVisible: true, failed_payments: failed, succeeded_payments: succeeded,
        });
        localStorage.setItem('payment_session_id', session_id);
      })
      .catch((error) => {
        this.setState({ isLoading: false, error: error.response.data.error });
        if (error.response.data.error) {
          message.error(error.response.data.error, 5);
        }
      });
  }

  convertToString = (value) => {
    if (!value) return '';
    return value.toLocaleString('en');
  }

  onChangeEmployeeType = (e) => {
    this.setState({ employees_filter: e.target.value });
    const filter = e.target.value;
    this.setState({ hidden: true });
    const data = this.store.payments.filter((value) => {
      if (filter === 'normal') {
        return value.employee_type === 'permanent' || value.employee_type === 'intern' || value.employee_type === 'non-permanent';
      }
      if (filter === 'comm') {
        return value.employee_type === 'commission';
      }
      if (filter === 'cont') {
        return value.employee_type === 'contractor';
      }
    });

    this.store.updateValue('filter_payments', data);
  };

  onSavePaymentRow = (oldValue, newValue, row, column) => {
    const filter = this.state.employees_filter;
    const item = { ...row };
    console.log({
      item_: item, oldValue, newValue_: newValue, column,
    });
    const element = this.store.filter_payments.findIndex((element) => element.eid === item.eid);
    const payment_element = this.store.payments.findIndex((element) => element.eid === item.eid);

    if (column.dataField === 'method') {
      this.store.filter_payments[element].method = newValue;
      this.store.payments[payment_element].method = newValue;
      return;
    }

    if (filter === 'normal') {
      if (isNaN(newValue)) return message.error(`Only numbers allowed in ${column.dataField}`);
      const amount = parseInt(Number(newValue.replace(/[^0-9\.]+/g, '')), 10); // parseInt(item.amount)
      this.store.filter_payments[element].amount = amount;
      this.store.filter_payments[element].wh_tax = 0;
      this.store.filter_payments[element].wh_tax_percent = 0;
      this.store.payments[payment_element].amount = amount;
      this.store.payments[payment_element].wh_tax = 0;
      this.store.payments[payment_element].wh_tax_percent = 0;
    }

    if (filter === 'comm') {
      let { net } = this.store.filter_payments[element];
      let { amount } = this.store.filter_payments[element];
      // if(isNaN(net)) net = Number(net.replace(/[^0-9\.]+/g,""))
      net = parseFloat(net.replace(/[^0-9\.]+/g, ''));
      const total_sales = item.total_sales && Number(item.total_sales.replace(/[^0-9\.]+/g, '')) || 0;
      const commission_percent = item.commission_percent && Number(item.commission_percent.replace(/[^0-9\.]+/g, '')) || 0;
      const wh_tax_percent = item.wh_tax_percent && Number(item.wh_tax_percent.replace(/[^0-9\.]+/g, '')) || 0;
      if (isNaN(commission_percent) || isNaN(total_sales)) return message.error('Only numbers allowed in commission percent and total sales');

      let commission = 0;
      let wh_tax = 0;
      let pre_amount = 0;

      if (commission_percent > 0) commission = (commission_percent / 100) * total_sales;
      if (wh_tax_percent > 0) wh_tax = (wh_tax_percent / 100) * commission;
      pre_amount = commission - wh_tax + net;

      if (column.dataField !== 'amount') {
        amount = Math.floor(pre_amount);
      } else {
        amount = parseInt(Number(newValue.replace(/[^0-9\.]+/g, '')), 10);
      }

      pre_amount = this.convertToString(pre_amount);

      this.store.filter_payments[element].commission = commission;
      this.store.filter_payments[element].wh_tax = wh_tax;
      this.store.filter_payments[element].amount = amount;
      this.store.filter_payments[element].pre_amount = pre_amount;

      this.store.payments[payment_element].commission = commission;
      this.store.payments[payment_element].wh_tax = wh_tax;
      this.store.payments[payment_element].amount = amount;
      this.store.payments[payment_element].pre_amount = pre_amount;
    }

    if (filter === 'cont') {
      if (isNaN(item.amount)) return message.error(`Only numbers allowed in ${column.dataField}`);
      this.store.filter_payments[element].amount = parseInt(Number(newValue.replace(/[^0-9\.]+/g, '')), 10);
      this.store.payments[payment_element].amount = parseInt(Number(newValue.replace(/[^0-9\.]+/g, '')), 10);
    }

    this.paymentsSum();
  }

  handlePaymentDateChange = (value, type) => {
    if (type === 'range' && value[0] && value[1]) {
      // range date
      this.setState({
        period_start: value[0], period_end: value[1], period_type: 'range', period_value: null,
      });
    }

    if (type === 'month') {
      this.setState({
        period_start: null, period_end: null, period_type: type, period_value: moment(value).format('M'),
      });
    }
    if (type === 'week') {
      this.setState({
        period_start: null, period_end: null, period_type: type, period_value: moment(value).format('w'),
      });
    }
    if (type === 'date') {
      this.setState({
        period_start: null, period_end: null, period_type: type, period_value: moment(value).format('L'),
      });
    }
  }

  paymentsSum() {
    const paid = this.store.payments.filter((element) => element.amount > 0);
    let amount_total = 0;

    for (let i = 0, len = paid.length; i < len; i++) {
      amount_total += paid[i].amount;
    }

    this.setState({ employee_count: paid.length, amount_total });
  }

  getEmployees = (empty_amount) => {
    this.setState({ error: null, isLoading: true });
    this.store.getEmployees(empty_amount)
      .then(() => {
        this.setState({ error: null, isLoading: false });
        this.paymentsSum();
      })
      .catch((error) => {
        message.error('Error getting employees');
        this.setState({ isLoading: false, error: error.response && error.response.data.error });
      });
  };

  onDateAlertClosed = () => {
  };

  componentDidMount() {
    if (this.store.employees.length === 0) {
      this.getEmployees();
    }
    this.paymentsSum();
    this.updateOrganisationAccBalance();
  }

  headerFormatter = (column, colIndex, { sortElement, filterElement }) => (
    <div>
      {column.text}
      {/* <br />
      {filterElement}
      {sortElement} */}
    </div>
  )

  activeStatusFormatter(cell, row) {
    if (row.is_active) {
      return (
        <span style={{ color: 'green' }}>
          Active
        </span>
      );
    }

    return (
      <span style={{ color: 'red' }}>
        Not Active
      </span>
    );
  }

  dateAgoFormatter(cell, row) {
    if (row.last_paid_ago) {
      return (
        <div>
          <span style={{ color: 'black' }}>
            {row.last_paid_date}
          </span>
          <br />
          <span style={{ color: 'grey' }}>
            {row.last_paid_ago}
          </span>
        </div>
      );
    }
  }

  tableNodata = (string) => (
    <div style={{
      paddingTop: '100px', paddingBottom: '100px', margin: 'auto', textAlign: 'center', backgroundColor: '#f8f8f8',
    }}
    >
      <Text>
        {' '}
        {`No ${string} found!`}
        {' '}
      </Text>
    </div>
  )

  priceFormatter(cell, row) {
    return (<PriceFormatter amount={cell} />);
  }

  MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div style={{ align: 'center' }}>
        <Button type="link" onClick={handleClick}>Download CSV</Button>
      </div>
    );
  };

  Payments = observer(() => {
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      hidePageListOnlyOnePage: true,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      sizePerPageList: [{
        text: '50', value: 50,
      }, {
        text: '100', value: 100,
      }, {
        text: 'All', value: this.store.filter_payments.length,
      }],
    };

    const paymentOptions = [{
      value: 'Bank Transfer',
      label: 'Bank Transfer',
    }, {
      value: 'Mpesa',
      label: 'Mpesa',
    }, {
      value: 'Cash',
      label: 'Cash',
    }, {
      value: 'Airtel Money',
      label: 'Airtel Money',
    }];

    let column = [];

    const normal_columns = [
      {
        dataField: 'pos', editable: false, text: 'No', ...positionFormatter,
      },
      {
        dataField: 'name', editable: false, text: 'Employee name', ...boldFormatter,
      },
      {
        dataField: 'employee_type', editable: false, text: 'Employee type', ...italicFormatter,
      },
      // { dataField: 'salary_billing', editable: false, text: 'Billing period',  ...italicFormatter },
      {
        dataField: 'method', editable: true, text: 'Payment method', editor: { type: Type.SELECT, options: paymentOptions },
      },
      // { dataField: 'advance', editable: false, text: 'Salary advance', filter: textFilter(),headerFormatter: this.headerFormatter, formatter: this.priceFormatter.bind(this) },
      // { dataField: 'savings', editable: false, text: 'Pay from savings', filter: textFilter(),headerFormatter: this.headerFormatter, formatter: this.priceFormatter.bind(this) },
      { dataField: 'last_paid_ago', text: 'Long ago', hidden: true },
      {
        dataField: 'last_paid_date', text: 'Last day successfully paid', editable: false, formatter: this.dateAgoFormatter.bind(this),
      },
      {
        dataField: 'bank_name', text: 'Bank', filter: textFilter(), editable: false, headerFormatter: this.headerFormatter,
      },
      {
        dataField: 'net', editable: false, text: 'Net income', align: 'right', formatter: this.priceFormatter.bind(this),
      },
      {
        dataField: 'amount', editable: true, text: 'Enter amount to pay:', align: 'right', classes: 'enter-payment',
      },
    ];

    const comm_columns = [
      {
        dataField: 'pos', editable: false, text: 'No', ...positionFormatter,
      },
      {
        dataField: 'name', editable: false, text: 'Employee', ...boldFormatter,
      },
      // { dataField: 'last_paid_date', editable: false, text: 'Last Paid',filter: textFilter(), headerFormatter: this.headerFormatter },
      {
        dataField: 'retainer_fee', editable: false, text: 'Retainer Fee',
      },
      {
        dataField: 'salary_billing', editable: false, text: 'Billing period', ...italicFormatter,
      },
      {
        dataField: 'method', editable: true, text: 'Payment method', editor: { type: Type.SELECT, options: paymentOptions },
      },
      {
        dataField: 'total_sales', text: 'Total Sales',
      },
      {
        dataField: 'commission_percent', text: 'Commission Percentage',
      },
      {
        dataField: 'commission', editable: false, text: 'Commission Earned',
      },
      // { dataField: 'advance', editable: false, text: 'Salary advance', filter: textFilter(),headerFormatter: this.headerFormatter, formatter: this.priceFormatter.bind(this) },
      {
        dataField: 'wh_tax_percent', editable: false, text: 'Witholding Tax Percentage',
      },
      {
        dataField: 'wh_tax', editable: false, text: 'Witholding Tax',
      },
      {
        dataField: 'pre_amount', editable: false, text: 'Net Income', align: 'right',
      },
      {
        dataField: 'amount', editable: true, text: 'Enter amount to pay', align: 'right', classes: 'enter-payment',
      },
    ];

    const cont_columns = [
      {
        dataField: 'pos', editable: false, text: 'No', ...positionFormatter,
      },
      {
        dataField: 'name', editable: false, text: 'Employee', ...boldFormatter,
      },
      // { dataField: 'employee_type', editable: false, text: 'Employee Type',  ...italicFormatter },
      // { dataField: 'last_paid_date', editable: false, text: 'Last Paid',filter: textFilter(), headerFormatter: this.headerFormatter },
      {
        dataField: 'salary_billing', editable: false, text: 'Billing period', ...italicFormatter,
      },
      {
        dataField: 'method', editable: true, text: 'Payment method', editor: { type: Type.SELECT, options: paymentOptions },
      },
      {
        dataField: 'retainer_fee', editable: false, text: 'Retainer Fee',
      },
      // { dataField: 'advance', editable: false, text: 'Salary advance', filter: textFilter(),headerFormatter: this.headerFormatter, formatter: this.priceFormatter.bind(this) },
      {
        dataField: 'wh_tax_percent', editable: false, text: 'Witholding Tax Percentage',
      },
      {
        dataField: 'wh_tax', editable: false, text: 'Witholding Tax',
      },
      {
        dataField: 'net', text: 'Net Income', align: 'right', formatter: this.priceFormatter.bind(this),
      },
      {
        dataField: 'amount', editable: true, text: 'Enter amount to pay', align: 'right', classes: 'enter-payment',
      },
    ];

    if (this.state.employees_filter === 'normal') column = normal_columns;
    if (this.state.employees_filter === 'comm') column = comm_columns;
    if (this.state.employees_filter === 'cont') column = cont_columns;

    return (
      <div className="grid_margin card">
        <ToolkitProvider
          keyField="pos"
          data={this.store.filter_payments}
          columns={column}
          search
          exportCSV
        >
          {
    (props) => (
      <div style={{
        display: 'flex', width: '100%', flexDirection: 'column',
      }}
      >
        <SearchBar {...props.searchProps} />
        <BootstrapTable
          {...props.baseProps}
          rowEvents={this.rowEvents}
          pagination={paginationFactory(options)}
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.onSavePaymentRow.bind(this),
          })}
        />
        <this.MyExportCSV {...props.csvProps} />
      </div>
    )
  }
        </ToolkitProvider>
        {/* <div>
          <BootstrapTable
            keyField="pos"
            bootstrap4
            noDataIndication={this.tableNodata('employees')}
            data={this.store.filter_payments}
            columns={column}
            filter={filterFactory()}
            // rowStyle={ rowStyle }
            // selectRow={ selectRow }
            rowEvents={this.rowEvents}
            pagination={paginationFactory(options)}
            cellEdit={cellEditFactory({
              mode: 'click',
              blurToSave: true,
              afterSaveCell: this.onSavePaymentRow.bind(this),
            })}
          />
        </div> */}
      </div>
    );
  })

  PickerWithType = ({ type, onChange }) => {
    if (type === 'month') return <MonthPicker placeholder="Select month of payment" onChange={onChange} />;
    if (type === 'date') return <DatePicker placeholder="Select day of payment" onChange={onChange} />;
    if (type === 'week') return <WeekPicker placeholder="Select week of payment" onChange={onChange} />;
    if (type === 'range') return <RangePicker placeholder="Select period of payment" onChange={onChange} />;
    return <DatePicker picker={type} onChange={onChange} />;
  }

  SwitchablePicker = () => {
    const [type, setType] = useState('month');
    return (
      <div>
        <Select value={type} onChange={setType}>
          <Option value="date">Date</Option>
          <Option value="week">Week</Option>
          <Option value="month">Month</Option>
          <Option value="range">Custom range</Option>
        </Select>
        &emsp;
        <this.PickerWithType type={type} onChange={(value) => this.handlePaymentDateChange(value, type)} />
        {!this.state.period_start && !this.state.period_end && !this.state.period_value
          && (
          <Alert
            message="Please select a date, week or month of payment!"
            type="error"
            closable
            onClose={this.onDateAlertClosed}
          />
          )
        }
      </div>
    );
  }

  PaymentsSummary = () => (
    <div style={{
      display: 'flex', width: '100%', flexDirection: 'column', marginRight: '20px', marginLeft: '20px', marginTop: '10px',
    }}
    >
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100% ',
      }}
      >

        <div style={{
          display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between',
        }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p className="main_header">Create payment</p>
              &emsp;
            <div>
              <this.SwitchablePicker />
            </div>
          </div>

          {/* <Radio.Group defaultValue="normal" onChange={this.onChangeEmployeeType} buttonStyle="solid">
            <Radio.Button value="normal">Normal</Radio.Button>
            <Radio.Button value="comm">Commission</Radio.Button>
            <Radio.Button value="cont">Contractors</Radio.Button>
          </Radio.Group> */}

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: '' }}>
            <div>
              <Text style={{ fontSize: '1.1em', fontFamily: 'Lato', fontWeight: '400' }}>Employees   </Text>
              <Text style={{
                fontSize: '1.4em', fontFamily: 'Lato', fontWeight: '600', color: '',
              }}
              >
                {this.state.employee_count}
              </Text>
            </div>

            <div>
              <Text style={{ fontSize: '1.1em', fontFamily: 'Lato', fontWeight: '400' }}>,     Amount   KES </Text>
              <Text style={{
                fontSize: '1.4em', fontFamily: 'Lato', fontWeight: '600', color: 'black',
              }}
              >
                {this.state.amount_total.toLocaleString('en')}
              </Text>
            </div>
          </div>

        </div>
      </div>
      <div style={{
        display: 'flex', width: '100%', flexDirection: 'row-reverse', marginRight: '20px', marginTop: '5px',
      }}
      >
        <Button type="primary" onClick={this.changeAmountstoNetPay}>
            Change amounts to net pay
        </Button>
        &emsp;&emsp;&emsp;
        <Button type="dashed" onClick={this.changeAmountstoZero}>
            Change amounts to zero
        </Button>
        &emsp;&emsp;&emsp;
        <Button type="default" onClick={this.updateOrganisationAccBalance}>
            Balance KES: {this.state.balance.toLocaleString('en')}
        </Button>
        &emsp;&emsp;
      </div>
    </div>
  )

  SubmitPayment = () => (
    <div style={{
      display: 'flex', width: 'auto', flexDirection: 'column', alignContent: 'flex-end', alignSelf: 'flex-end', marginRight: '20px', marginLeft: '20px', marginTop: '25px',
    }}
    >
      <div style={{
        display: 'flex', width: '40%', flexDirection: 'row', alignContent: 'flex-end', alignSelf: 'flex-end',
      }}
      >
        <Text style={{ fontSize: '1.1em', fontFamily: 'Lato', fontWeight: '400' }}>Enter payment name </Text>
      </div>
      <div style={{
        display: 'flex', width: '40%', flexDirection: 'row', alignContent: 'flex-end', alignSelf: 'flex-end',
      }}
      >

        <Input placeholder="eg April Payroll" style={{ width: '100%' }} maxlength="14" onChange={this.onPaymentNameChange} />
          &emsp;
        <Button type="primary" disabled={this.state.disable_payment_button} onClick={this.onDrawerOPen}>
            Submit payment(s)
        </Button>
      </div>

      <div />
    </div>
  )

  EmployeesSummary = () => (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <p className="main_header">Employees income summary</p>
    </div>
  )

  hidePaymentResultsModal = () => {
    this.setState({ paymentResultModalVisible: false, succeeded_payments: [], failed_payments: [] });
  }

  createListFromArray = (data) => (
    data.map((x) => (
      <p styles={{ margin: '0px' }}>
        {' '}
        {x.name}
,
        {' '}
        <span styles={{ color: 'green' }}>{x.amount}</span>
,
        {' '}
        <span styles={{ color: 'grey' }}>{x.method}</span>
      </p>
    ))
  )

  PaymentSummary = () => {
    const columns = [
      {
        title: 'Name of employee',
        dataIndex: 'name',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Amount',
        className: 'amount',
        dataIndex: 'amount',
      },
    ];
    let data = JSON.parse(JSON.stringify(this.store.payments));
    data = data.filter((value) => value.amount > 0);
    data.forEach((item) => {
      item.amount = item.amount.toLocaleString('en');
    });
    return (
      <div className="overx">
        <Grid>
          <this.PaymentsSummary />
          {this.state.isLoading
            ? <Loader />
            : (
              <Grid.Row columns={1}>
                <Grid.Column>
                  <this.Payments />
                </Grid.Column>
                <Grid.Column>
                  <this.SubmitPayment />
                </Grid.Column>
              </Grid.Row>
            )

          }
        </Grid>

        <Drawer
          title="Confirm Payments"
          width={500}
          closable={false}
          onClose={this.onDrawerClose}
          visible={this.state.drawerVisible}
        >

          <Modal
            title="Payments results"
            centered
            visible={this.state.paymentResultModalVisible}
            onOk={() => this.hidePaymentResultsModal()}
            onCancel={() => this.hidePaymentResultsModal(false)}
          >
            <br />
            <Text type="success" strong> Successfully submitted for processing </Text>
            Total: {this.state.succeeded_payments.length}
            <Divider />
            {this.createListFromArray(this.state.succeeded_payments)}
            <br />
            <Text type="danger" strong> Failed payments </Text>
            Total: {this.state.failed_payments.length}
            <Divider />
            {this.createListFromArray(this.state.failed_payments)}
            <br />
            <Text type="info" strong> Check payments page for processing status</Text>
          </Modal>

          <Table
            columns={columns}
            dataSource={data}
            bordered
            title={() => `Payments for ${this.state.payment_name}`}
            footer={() => `Total Employees: ${this.state.employee_count}, Total Amount: KES ${this.state.amount_total.toLocaleString('en')}`}
          />

          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
          >
            <Button style={{ marginRight: 8 }} onClick={this.onDrawerClose}>
              Cancel
            </Button>
            <Button onClick={this.sendPayments} type="primary">
              Submit
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/`}
          render={() => (
            <this.PaymentSummary />
          )}
        />
      </Switch>
    );
  }
}

export default Payments;
