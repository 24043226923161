/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import {
  Button, Typography, Modal, Upload, Alert, message, Tag,
} from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Loader from '../../../components/Loader';
import EmployeeDetails from './EmployeeDetails';
import EmployeeForm from './EmployeeForm';
import PriceFormatter from '../../../components/PriceFormatter';
import { boldFormatter, italicFormatter } from '../../../components/TableFormatter';

const { Text } = Typography;

@inject('store') class Employees extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoading: false,
      error: null,
      total: 0,
      active: 0,
      in_active: 0,
      selected: 0,
      showModal: false,
      uploadSuccess: null,
      uploadMessage: null,
    };
  }

    // handle form input
    handleChange = (e) => {
      const change = {};
      change[e.target.name] = e.target.value;
      this.setState(change);
    };

    rowEvents = {
      onClick: (e, row) => {
        const item = { ...row };
        const index = this.store.employees.findIndex((element) => element.eid === item.eid);
        this.setState({ selected: index });
        this.props.history.push(`employees/${index}/details`);
      },
    };

    addNewEmployee = () => {
      this.props.history.push('employees/add');
    }

    addNewEmployeeFromXcelFile = () => {
      this.setState({ showModal: true });
    }

    importEmployeesFromEpesiLoan = () => {
      const employeeImport = message.loading('Import in progress..', 0);
      const data = { organisation_id: sessionStorage.getItem('organisation_id') };
      axios.post(`${this.store.domain}/employees/loanapp/import`, data, { headers: { authorization: sessionStorage.getItem('token') } })
        .then((response) => {
          setTimeout(employeeImport, 2);
          this.setState({ success: true, error: null });
          message.success(response.data.data, 10);
        })
        .catch((error) => {
          setTimeout(employeeImport, 2);
          message.error(`Error: ${error.response.data.error || 'Something went wrong'}`, 10);
        });
    }

    getEmployees = () => {
      this.setState({ error: null, isLoading: true });
      this.store.getEmployees()
        .then(() => {
          this.setState({ error: null, isLoading: false });
        })
        .catch((error) => {
          this.setState({ isLoading: false, error: error.response && error.response.data.error });
        });
    };

    componentDidMount() {
      if (this.store.employees.length === 0) {
        console.log('getting employees');
        this.getEmployees();
      }
    }

    hideModal = () => {
      this.setState({ showModal: false, uploadMessage: null, uploadSuccess: null });
    }

    headerFormatter = (column, colIndex, { filterElement }) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        { column.text }
        { filterElement }
      </div>
    )

    activeStatusFormatter(cell, row) {
      if (row.is_active) {
        return (<Tag color="#108ee9">active</Tag>);
      }
      return (<Tag color="#f50">not active</Tag>);
    }

    tableNodata = (string) => (
      <div style={{
        paddingTop: '100px', paddingBottom: '100px', margin: 'auto', textAlign: 'center', backgroundColor: '#f8f8f8',
      }}
      >
        <Text>
          {' '}
          {`No ${string} found!`}
          {' '}
        </Text>
      </div>
    )

    priceFormatter(cell, row) {
      return (<PriceFormatter amount={cell} />);
    }

    EmployeeList = observer(() => {
      const options = {
        paginationSize: 4,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        sizePerPageList: [
          { text: '40', value: 20 },
          { text: '50', value: 50 },
          { text: '80', value: 80 },
          { text: '100', value: 100 },
          { text: 'All', value: this.store.employees.length },
        ],
      };

      const columns = [
        { dataField: 'pos', text: '' },
        {
          dataField: 'name', text: 'Staff name', filter: textFilter(), headerFormatter: this.headerFormatter, ...boldFormatter,
        },
        {
          dataField: 'job_desc', text: 'Position', filter: textFilter(), headerFormatter: this.headerFormatter,
        },
        // { dataField: 'department', text: 'Department', filter: textFilter(),headerFormatter: this.headerFormatter },
        {
          dataField: 'employee_type', text: 'Employee type', filter: textFilter(), headerFormatter: this.headerFormatter, ...italicFormatter,
        },
        // { dataField: 'basic_salary', text: 'Salary/ Lump sum', filter: textFilter(),headerFormatter: this.headerFormatter },
        {
          dataField: 'retainer_fee', text: 'Retainer', align: 'right', filter: textFilter(), headerFormatter: this.headerFormatter, formatter: this.priceFormatter.bind(this),
        },
        {
          dataField: 'gross', text: 'Gross income', align: 'right', filter: textFilter(), headerFormatter: this.headerFormatter, formatter: this.priceFormatter.bind(this),
        },
        {
          dataField: 'net_pay', text: 'Net income', align: 'right', filter: textFilter(), headerFormatter: this.headerFormatter, formatter: this.priceFormatter.bind(this),
        },
        // { dataField: 'is_taxable', text: 'Taxable', filter: textFilter(),headerFormatter: this.headerFormatter },
        {
          dataField: 'is_active', text: 'Status', align: 'center', filter: textFilter(), headerFormatter: this.headerFormatter, formatter: this.activeStatusFormatter,
        },
      ];

      return (
        <div className="grid_margin card">
          <div>
            <BootstrapTable
              keyField="pos"
              bootstrap4
              noDataIndication={this.tableNodata('employees')}
              data={this.store.employees}
              columns={columns}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
                    // rowStyle={ rowStyle }
                    // selectRow={ selectRow }
              rowEvents={this.rowEvents}
            />
          </div>
        </div>
      );
    })

    AddNewEmployeeFromExcel = () => {
      const that = this;
      const props = {
        name: 'employees_excel',
        action: `${this.store.domain}/employees/${sessionStorage.getItem('organisation_id')}/upload_employees`,
        headers: {
          authorization: sessionStorage.getItem('token'),
        },
        onChange(info) {
          if (info.file.status !== 'uploading') console.log(info);
          if (info.file.status === 'done') {
            that.setState({ uploadMessage: info.file.response.info, uploadSuccess: true });
            that.getEmployees();
          } else if (info.file.status === 'error') {
            that.setState({ uploadMessage: info.file.response.error, uploadSuccess: false });
          }
        },
      };

      return (
        <Modal
          title="Upload excel document"
          centered
          visible={this.state.showModal}
          onOk={() => this.hideModal()}
          onCancel={() => this.hideModal()}
        >

          <p>
Make sure the excel document contain the folowing fields:
            <br />
            {' '}
            <strong>
          staff or name
              {' '}
              <br />
              {' '}
phone number
              {' '}
              <br />
              {' '}
email
              {' '}
              <br />
              {' '}
gender
              <br />
date of birth
              {' '}
              <br />
              {' '}
country
              {' '}
              <br />
              {' '}
job title
              {' '}
              <br />
              {' '}
department
              <br />
              {' '}
employee type
              {' '}
              <span style={{ color: 'grey' }}>
                <i>i.e permanent or contractor or commission or intern or non-permanent </i>
                {' '}
              </span>
              <br />
              {' '}
gross
              <br />
              {' '}
bank
              <br />
              {' '}
bank account details
              <span style={{ color: 'grey' }}>
                <i>i.e account no </i>
                {' '}
              </span>
              {' '}
              <br />
          commission
              {' '}
              <br />
              {' '}
nssf
              {' '}
              <br />
nssf number
              {' '}
              <br />
nhif
              {' '}
              <br />
nhif number
              {' '}
              <br />
              {' '}
w/tax
              {' '}
              <br />
              {' '}
paye
              {' '}
              <br />
              {' '}
insurance
              {' '}
              <br />
              {' '}
              housing levy
              {' '}
              <br />
              {' '}
net pay
            </strong>
          </p>

          <br />
          <br />

          <Upload {...props}>
            <Button>Click to Upload</Button>
          </Upload>

          <br />
          <br />
          <div>
            {this.state.uploadSuccess && <Alert message="Success uploading." description={this.state.uploadMessage.replace(',', '\r\n')} type="success" showIcon />}
            {this.state.uploadSuccess === false && <Alert message="Error." description={this.state.uploadMessage} type="errror" showIcon />}
          </div>
        </Modal>
      );
    }

    EmployeesSummary = () => (
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', marginLeft: '20px', marginTop: '20px',
      }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p className="main_header">Employees</p>
        </div>
        <div>
          <Button type="link" icon="plus" onClick={this.addNewEmployee}>
                Add new employee
          </Button>
            &emsp;
          <Button type="link" icon="cloud-upload" onClick={this.addNewEmployeeFromXcelFile}>
                Upload excel file
          </Button>
            &emsp;
          <Button type="link" icon="cloud-upload" onClick={this.importEmployeesFromEpesiLoan}>
                Import employees from Epesi Loan
          </Button>
        </div>
      </div>
    )

    SimpleSummary = (props) => {
      const colorss = props.colors;
      return (
        <div className="" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <div>
            <p style={{
              fontSize: '1.2em', marginTop: '0px', marginBottom: '20px', fontFamily: 'Lato', fontWeight: '700',
            }}
            >
              {props.keys.toUpperCase()}
            </p>
          </div>

          <div>
            <p style={{
              fontSize: '2.8em', marginTop: '0px', marginBottom: '20px', fontFamily: 'Lato', fontWeight: '900', color: colorss,
            }}
            >
              {props.values}
            </p>
          </div>
        </div>
      );
    }

    EmployeesPage = () => (
      <div>
        <this.EmployeesSummary />
        { this.state.isLoading
          ? <Loader />
          : (
            <div className="overx">
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <this.EmployeeList />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )
           }
        <this.AddNewEmployeeFromExcel />
      </div>
    )

    render() {
      return (
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}/`}
            render={() => (
              <this.EmployeesPage />
            )}
          />

          <Route
            exact
            path={`${this.props.match.path}/:employee_id/details`}
            render={(props) => (
              <EmployeeDetails
                {...props}
                employee={this.store.employees[this.state.selected]}
              />
            )}
          />

          <Route
            exact
            path={`${this.props.match.path}/:employee_id/edit`}
            render={(props) => (
              <EmployeeForm
                {...props}
                mode="edit"
                selected_item="details"
                employee={this.store.employees[this.state.selected]}
                getEmployees={this.getEmployees}
              />
            )}
          />

          <Route
            exact
            path={`${this.props.match.path}/add`}
            render={(props) => (
              <EmployeeForm
                mode="add"
                selected_item="details"
                {...props}
              />
            )}
          />

        </Switch>
      );
    }
}

export default Employees;
