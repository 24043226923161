import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  Layout, Menu, Icon, Typography,
} from 'antd';
// import images
import logo from '../images/logo.png';
import dil from '../images/DIL-GROUP-LOGO.png';

const { SubMenu } = Menu;

const { Text } = Typography;

@inject('store')
@observer
class VerticalMenu extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      error: null,
      activeItem: 'profile_account',
    };
  }

  menuCollapse = () => {
    this.store.updateValue('menu_collapsed', !this.store.menu_collapsed);
  }

  // componentDidMount() {
  //       //this.getOrganization();
  // };

  // menuItem = (props) => {
  //   return (
  //      <Container
  //       style={{paddingTop:'10px', paddingBottom: '10px'}}
  //       as={Link}
  //       to={props.loc}
  //       className={props.value === this.state.activeItem ? 'menu_selected' : ''}
  //       value={props.name}
  //       onClick={() => this.handleActiveItem(props.value)}>

  //       <p  className={props.value === this.state.activeItem ? 'menu_text_selected' : 'menu_text'} > {props.name} </p>

  //      </Container>
  //   )
  //  }

  //   profileMenu = () => {
  //       return(
  //       <div style={{ display: 'flex',flexDirection:'column', alignItems: 'left', marginLeft: '20%',marginTop:'10px'}}>
  //       {<this.menuItem name='Account' value='profile_account' loc='/account'/>}
  //       {<this.menuItem name='Payments' value='profile_payments' />}
  //       {<this.menuItem name='Settings' value='profile_settings' loc='/account/settings/' />}
  //       {<this.menuItem name='Messages' value='profile_messages'/>}
  //       </div>
  //       )
  //   }

  //   helpMenu = () => {
  //       return(
  //       <div style={{ display: 'flex',flexDirection:'column', alignItems: 'left', marginLeft: '20%',marginTop:'10px'}}>
  //       {<this.menuItem name='Contacts' value='help_contacts'/>}
  //       {<this.menuItem name='About' value='help_about'/>}
  //       </div>
  //       )
  //   }

  //   organizationMenu = () => {
  //       return(
  //           <div style={{ display: 'flex',flexDirection:'column', alignItems: 'left', marginLeft: '20%',marginTop:'10px'}}>
  //           {<this.menuItem name='Employees' value='org_employees' loc='/organization/employees' />}
  //           {<this.menuItem name='Accounts' value='org_accounts'/>}
  //           {<this.menuItem name='Payments' value='org_payments' loc='/organization/payments' />}
  //           {<this.menuItem name='Settings' value='org_settings' loc='/organization/settings' />}
  //           {<this.menuItem name='Messages' value='org_messages'/>}
  //           </div>
  //           )
  //   }

  //   menuHeader = (props) => {
  //      return(

  //       <Container style={{width: '100%', paddingTop: '3px', paddingBottom: '3px', marginTop: '5px', display: 'flex',flexDirection:'row', alignItems: 'center', justifyContent: 'space-between'}}>
  //       <p style={{fontFamily: 'Roboto',fontWeight:'500', fontSize: '1em', color: '#2955C8', textAlign: 'center', marginLeft: '10%', marginTop: '20px'}}>{props.name}</p>
  //       <Icon inverted color='grey' name='caret right' style={{textAlign: 'center', marginRight: '8px'}}/>
  //       </Container>

  //      )
  //   }

  render() {
    const type = sessionStorage.getItem('type');
    return (

      <Layout className="fixed_sider" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Menu
          onClick={this.handleClick}
          style={{ width: this.store.menu_collapsed ? '80px' : '200px' }}
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1', 'sub2', 'sub4']}
          mode="inline"
          inlineCollapsed={this.store.menu_collapsed}
        >

          <div style={{
            height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <img alt="logo" src={logo} style={{ width: '60px' }} />
            <p className="mapato_header"> Mapato</p>
          </div>

          { type === 'employee'
        && (
        <SubMenu
          key="sub1"
          title={(
            <span>
              <Icon type="user" />
              <span>Your profile</span>
            </span>
)}
        >

          <Menu.Item key="1">
            <span>
              <Icon type="table" />
              <span>Account</span>
            </span>
            <Link to="/account" />
          </Menu.Item>
          <Menu.Item key="2">
            <span>
              <Icon type="dollar" />
              <span>Payments</span>
            </span>
            <Link to="/payments" />
          </Menu.Item>
          <Menu.Item key="3">
            <span>
              <Icon type="tool" />
              <span>Settings</span>
            </span>
            <Link to="/account" />
          </Menu.Item>
          <Menu.Item key="4">
            <span>
              <Icon type="inbox" />
              <span>Messages</span>
            </span>
            <Link to="/account" />
          </Menu.Item>

        </SubMenu>
        )
       }

          { type === 'business'

        && (
        <SubMenu
          key="sub2"
          title={(
            <span>
              <Icon type="appstore" />
              <span>Organisation</span>
            </span>
)}
        >

          <Menu.Item key="5">
            <span>
              <Icon type="table" />
              <span>Account</span>
            </span>
            <Link to="/organization" />
          </Menu.Item>
          <Menu.Item key="6">
            <span>
              <Icon type="team" />
              <span>Employees</span>
            </span>
            <Link to="/organization/employees" />
          </Menu.Item>

          {/* <Menu.Item key="8">
          <span>
          <Icon type="tool" />
            <span >Settings</span>
          </span>
          <Link to={'/organization/settings'} />
        </Menu.Item> */}
          {/* <Menu.Item key="11">
          <span>
          <Icon type="bank" />
            <span >Bank Accounts</span>
          </span>
          <Link to={'/organization/settings'} />
        </Menu.Item>
        <Menu.Item key="9">
          <span>
          <Icon type="inbox" />
            <span >Messages</span>
          </span>
          <Link to={'/organization/messages'} />
        </Menu.Item> */}

        </SubMenu>
        )

        }

          { type === 'business'

        && (
        <SubMenu
          key="sub4"
          title={(
            <span>
              <Icon type="dollar" />
              <span>Salaries</span>
            </span>
)}
        >

          <Menu.Item key="12">
            <span>
              <Icon type="dollar" />
              <span>Create payment</span>
            </span>
            <Link to="/organization/payments" />
          </Menu.Item>

          <Menu.Item key="13">
            <span>
              <Icon type="dollar" />
              <span>All payments</span>
            </span>
            <Link to="/organization/payments/all" />
          </Menu.Item>
        </SubMenu>
        )
        }

          {/* <SubMenu
          key="sub3"
          title={
            <span>
              <Icon type="question" />
              <span >Help</span>
            </span>
          }>

        <Menu.Item key="10">
          <span>
            <Icon type="mail" />
            <span>Messages</span>
          </span>
        <Link to={'/account'} />
        </Menu.Item>
        </SubMenu> */}

        </Menu>

        <div
          className="paddless_p"
          style={{
            width: '100%', display: 'flex', flexDirection: 'column', justifyContent: '', alignItems: 'center',
          }}
        >
          <img alt="logo" src={dil} style={{ width: '60px' }} />
          <Text>{this.store.menu_collapsed ? '' : 'Data Integrated Limited'}</Text>
          <p className="mapato_header" />
          <p className="mapato_header"> &copy; 2024 </p>
          <div
            style={{
              backgroundColor: '#1890ff', height: '40px', width: '100%', display: 'flex', alignContent: 'center',
            }}
            className="flex_center"
            onClick={this.menuCollapse}
          >
            <Icon style={{ color: 'white' }} type={this.store.menu_collapsed ? 'right' : 'left'} />
          </div>
        </div>
      </Layout>
    );
  }
}

export default VerticalMenu;
