import { observable, computed, action } from 'mobx';
import axios from 'axios';
import moment from 'moment';
import utils from './pages/organization/employees/utils';

class Store {
    // @observable domain = 'http://192.168.0.124:4080';
    // @observable domain = 'http://localhost:4080';
    @observable domain = 'https://payrollapi.mobitill.com';

    @observable user_id = '';

    @observable employee_details_cache_eid = '';

    @observable isLoading = '0';

    @observable menu_collapsed = false;

    @observable organisations = [];

    @observable loggedInPerson = '';

    @observable banks = [];

    @observable employee = {};

    @observable employees = [];

    @observable payments = [];

    @observable filter_payments = [];

    @observable amounts = [];

    @observable payments_list = [];

    @observable user_is_active = true;

    @observable organisation_name = '';

    @observable organisation_address ='';

    @observable organisation_country ='';

    @observable organisation_phone = [];

    @observable organisation_email =[];

    @observable organisation_currency =[];

    @observable organisation_users =[];

    @action updateValue(key, value) {
      this[key] = value;
      // console.log(value)
    }

    @action clearEmployeesData() {
      this.employee = {};
      this.employees = [];
      this.payments = [];
      this.filter_payments = {};
      this.amounts = [];
      this.payments_list = [];
      this.banks = [];
    }

    @action clearOrganizations(key, value) {
      this.organisation_address = '';
      this.organisation_alias = '';
      this.organisation_country = '';
      this.organisation_date_created = '';
      this.organisation_email = '';
      this.organisation_name = '';
    }

    @computed get getUserNames() {
      return `${this.first_name} ${this.last_name} ${this.other_name}`;
    }

    @computed get getTotalAllowances() {
      let allowances_total = 0;
      Object.values(this.allowances).forEach((value) => {
        allowances_total += value.amount;
      });
      return allowances_total;
    }

    @computed get getTotalDeductions() {
      let deductions_total = 0;
      Object.values(this.deductions).forEach((value) => {
        deductions_total += value.amount;
      });
      return deductions_total;
    }

    @computed get getNetPay() {
      return this.basic_salary + this.getTotalAllowances - this.getTotalDeductions;
    }

    @computed
    get getOrganisationsCount() {
      return this.isEmployee ? this.organizations_employeed.length : this.organizations_owned.length;
    }

    @computed get getAllOrganisations() {
      const orgs = this.organizations_owned.concat(this.organizations_employeed);
      return orgs;
    }

    @computed get getSelectedOrganisation() {
      return '';
    }

    @action
    clearAll() {
      this.user_id = '';
      this.isLoading = '0';
      this.menu_collapsed = false;
      this.organisations = [];
      this.loggedInPerson = '';
      this.banks = [];
      this.employee = [];
      this.employees = [];
      this.payments = [];
      this.filter_payments = [];
      this.amounts = [];
      this.user_is_active = true;
      this.organisation_name = '';
      this.organisation_address = '';
      this.organisation_country = '';
      this.organisation_phone = [];
      this.organisation_email = [];
      this.organisation_users = [];
      this.organisation_alias = '';
      this.organisation_date_created = '';
    }

    postMonitoringData = (section, category) => {
      const body = {};
      body.app_name = 'Payroll';
      body.username = this.loggedInPerson;
      body.section = section;
      body.category = category;
      body.token = sessionStorage.getItem('token');
      body.date = moment().valueOf();

      const host = 'https://monitoring.mobitill.com/api/activity';
      axios.post(`${host}/add_activity`, body)
        .then((response) => {
          console.log('Monitoring response: ', response.data);
        })
        .catch(console.error);
    }

    getEmployees = (empty_amount = false) => {
      const config = {
        headers: { authorization: sessionStorage.getItem('token') },
        params: {
          organisation_id: sessionStorage.getItem('organisation_id'),
          user_id: sessionStorage.getItem('userID'),
        },
      };
      return axios.get(`${this.domain}/employees`, config)
        .then((response) => {
          const data = utils.calculateEmployeeSummary(response.data.data.employees, response.data.data.amounts, response.data.data.banks);
          this.updateValue('employees', data);

          const uid = sessionStorage.getItem('uid');
          const organisation_id = sessionStorage.getItem('organisation_id');

          if (!uid) {
            // return message.error('Could not load employees. Please Log out then Log in again')
          }

          const payments = [];
          let position = 1;
          data.forEach((element) => {
            const obj = {};
            obj.pos = position;
            obj.eid = element.eid;
            obj.uid = uid;
            obj.organisation_id = organisation_id;
            obj.ref_no = '';
            obj.type = '';
            obj.bank_name = element.bank_name;
            obj.amount = empty_amount === true ? 0 : parseInt(element.net_pay, 10);
            obj.last_paid_ago = element.last_paid_ago;
            // element.pay_salary_from_savings === true ? obj.amount = parseFloat(element.loan_app_savings_total) : obj.amount = Math.floor(parseFloat(element.net_pay))
            obj.savings = element.loan_app_savings_total;
            obj.method = 'Bank Transfer';
            obj.total_sales = 0;
            obj.commission = 0;
            obj.commission_percent = 0;
            obj.wh_tax = element.wh_tax;
            obj.name = element.name;
            obj.employee_type = element.employee_type;
            obj.retainer_fee = element.retainer_fee;
            obj.salary_billing = element.salary_billing;
            obj.net = element.net_pay;
            obj.pre_amount = element.net_pay;
            obj.last_paid_date = element.last_paid_date;
            obj.wh_tax_percent = `${element.wh_tax_percent || 0}%`;
            obj.include_retainer_in_tax = element.include_retainer_in_tax;
            payments.push(obj);
            position++;
          });

          this.updateValue('payments', payments);

          const filtered = payments.filter((value) => value.employee_type === 'permanent' || value.employee_type === 'intern' || value.employee_type === 'non-permanent');
          this.updateValue('filter_payments', filtered);
          return Promise.resolve(true);
        })
        .catch((error) => Promise.reject(error));
    };
}

export default new Store();
